import React, { useEffect, useState, useContext } from "react";
import API_SERVICE, { encryptData } from "../services/api.service";
import {
  AuthContainer,
  AuthRightSideWrapper,
  Button,
  DataWrapper,
  Heading,
  Input,
  InputWrapper,
  LinkWrapper,
  Para,
  ShowPasswordICon,
} from "../components/stylecomponents/stylecomponents";
import BannerSection from "../components/authComponents/BannerSection";
import { Link, useNavigate } from "react-router-dom";
import { getDeviceInfo } from "../constants/common";
import {
  USER_STORAGE,
  USER_STORE_TOKEN_KEY,
  USER_STORE_USER_KEY,
  addUpdateItem,
} from "../services/SecuredDatabaseHelper";
import { AuthContext } from "../components/authComponents/AuthProvider";
import openEye from "../assets/openeye.png";
import closeEye from "../assets/closeeye.png";

export default function SignUp() {
  const [userData, setUserData] = useState({});
  const [deviceInfo, setDeviceInfo] = useState();
  const [inputError, setInputError] = useState({
    password: false,
    confirmpassword: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);
  useEffect(() => {
    const fetchDetails = async () => {
      const { deviceInfo: deviceData, error } = await getDeviceInfo();
      setDeviceInfo(deviceData);
    };
    fetchDetails();
  }, []);

  const handleChange = (event) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const saveToIndexDb = async (response) => {
    const storeresponse = {
      authToken: response?.authToken,
      firstName: response?.firstName,
      lastName: response?.lastName,
    };
    await addUpdateItem(response?.authToken, USER_STORE_TOKEN_KEY, USER_STORAGE);
    await addUpdateItem(storeresponse, USER_STORE_USER_KEY, USER_STORAGE);
    navigate("/");

  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      !userData?.username ||
      !userData?.firstname ||
      !userData?.lastname ||
      !userData?.password ||
      !userData?.confirmpassword
    ) {
      return;
    }
    const { data: api_response } = await API_SERVICE.signup({
      userName: userData?.username,
      password: userData?.password,
      deviceName: deviceInfo?.deviceName,
      deviceType: "PC_BROWSER",
      fcmIdAndroid: null,
      firstName: userData?.firstname,
      lastName: userData?.lastname,
      deviceNumber: deviceInfo?.deviceId,
    });
    if (api_response?.errorCode === 0) {
      if (api_response && api_response?.data) {
        let temp = { ...api_response?.data };
        const decToken = encryptData(temp?.authToken);
        const decFistname = encryptData(temp?.firstName);
        const decLastname = encryptData(temp?.lastName);
        temp["authToken"] = decToken;
        temp["firstName"] = decFistname;
        temp["lastName"] = decLastname;
        await saveToIndexDb(temp);
      }
  
    }
  };

  /**
   * @description regexStatus true means error occured regex patten
   * @param {import("react").ChangeEvent<HTMLInputElement>} event
   * @param {boolean} regexStatus
   */
  const handleInputError = (event, regexStatus) => {
    const name = event.target.name;
    if (regexStatus && !inputError?.[name]) {
      setInputError((prevError) => {
        return {
          ...prevError,
          [name]: true,
        };
      });
    } else if (!regexStatus && inputError?.[name]) {
      setInputError((prevError) => {
        return {
          ...prevError,
          [name]: false,
        };
      });
    }
  };

 

  return (
    <AuthContainer>
      <BannerSection />
      <AuthRightSideWrapper>
        <DataWrapper>
          <Heading>Sign Up</Heading>
          <Para>Enter the details to Sign Up</Para>
        </DataWrapper>
        <InputWrapper>
          <Input
            name="username"
            placeholder="Username"
            type="text"
            value={userData?.username || ""}
            onChange={(event) => {
              handleChange(event);
              const userNmaeRegx = new RegExp(configuration?.USERNAME_REGEX);
              if (!userNmaeRegx.test(event?.target?.value)) {
                handleInputError(event, true);
              } else handleInputError(event, false);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="Firstname"
            name="firstname"
            type="text"
            value={userData?.firstname || ""}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="Lastname"
            name="lastname"
            type="text"
            value={userData?.lastname || ""}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={userData?.password || ""}
            onChange={(event) => {
              handleChange(event);
              const passwordRegx = new RegExp(configuration?.PASSWORD_REGEX);
              if (!passwordRegx.test(event?.target?.value)) {
                handleInputError(event, true);
              } else handleInputError(event, false);
            }}
          />
          <ShowPasswordICon
            src={showPassword ? openEye : closeEye}
            onClick={() => setShowPassword(!showPassword)}
          />
          {inputError.password && (
            <span style={{ fontSize: "10px", color: "red", marginTop: "5px" }}>
              {
                "* Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-16 characters long."
              }
            </span>
          )}
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="Confirm password"
            name="confirmpassword"
            type={showConfirmPassword ? "text" : "password"}
            value={userData?.confirmpassword || ""}
            onChange={(event) => {
              handleChange(event);
              const passwordRegx = new RegExp(configuration?.PASSWORD_REGEX);
              if (!passwordRegx.test(event?.target?.value)) {
                handleInputError(event, true);
              } else handleInputError(event, false);
            }}
          />
          <ShowPasswordICon
            src={showConfirmPassword ? openEye : closeEye}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
          {inputError.confirmpassword && (
            <span style={{ fontSize: "10px", color: "red", marginTop: "5px" }}>
              {
                "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be 8-16 characters long."
              }
            </span>
          )}
        </InputWrapper>

        <Button onClick={onSubmitHandler}>Sign Up</Button>
        <LinkWrapper style={{ justifyContent: "center" }}>
          Already have a account? <Link to={"/login"}> Login</Link>
        </LinkWrapper>
      </AuthRightSideWrapper>
    </AuthContainer>
  );
}
