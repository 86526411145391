import { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import {
  USER_STORAGE,
  USER_STORE_TOKEN_KEY,
  getItem,
} from "../../services/SecuredDatabaseHelper";

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const tokenNumber = await getItem(USER_STORE_TOKEN_KEY, USER_STORAGE);
      setToken(tokenNumber);
      setLoading(false);
    })();
  }, []);

  if (!loading) {
    return !token ? navigate("/login") : <Outlet />;
  }
};

export default PrivateRoute;
