import axios, { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "./axiosClient";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { USER_STORAGE, deleteDatabase } from "./SecuredDatabaseHelper";

/**
 * @param {object} records
 * @returns
 */
export function createHeader(records) {
  const headers = {};
  const keys = Object.keys(records);

  if (keys?.length) {
    for (let index = 0; index < keys?.length; index++) {
      const key = keys[index];
      headers[key] = records[key];
    }
  }
  return headers;
}

/**
 * @param {string} message
 */
export const errorAlertPop = (message) => {
  Swal.fire({
    title: message,
    text: "",
    icon: "error",
    showConfirmButton: true,
    timer: 5000, // Timeout duration in milliseconds
    timerProgressBar: true,
  });
};

/**
 * @param {string} message
 */
export const sucessAlertPop = (message) => {
  Swal.fire({
    title: message,
    text: "",
    icon: "success",
    showConfirmButton: true,
    timer: 5000, // Timeout duration in milliseconds
    timerProgressBar: true,
  });
};

/**
 * @param {number} errorCode
 * @returns
 */
export const axiosErrorHandling = async (errorCode) => {
  if (errorCode === 400) {
    /*Bad request */
    return "Invalid request";
  } else if ([413, 401].includes(errorCode)) {
    /*Unauthorized request */
    return "Access denied";
  } else if (errorCode === 404) {
    /*Not found exception*/
    return "The requested information could not be found";
  } else if (errorCode === 409) {
    /*conflict expection */
    return "Conflict occurred";
  } else if ([500, 503, 502, 504].includes(errorCode)) {
    /*Internal server error */
    return "Service under maintainance! Please try after some time.";
  } else {
    return "The connection has timed out, please try again.";
  }
};

/**
 * @param {Error} error
 */
export const otherErrors = async (error) => {
  const errorCode = error?.errorCode;
  if ([102, 105, 108].includes(errorCode)) {
    /* Session expired */
    errorAlertPop(error?.errorMessage);
    setTimeout(async() => {
      const url = `${window?.location?.origin}/#/login`;
      await deleteDatabase(USER_STORAGE);
      window.location.replace(url);
    },1000)

  } else if (errorCode) {
    errorAlertPop(error?.errorMessage);
  } else {
    errorAlertPop(error?.message || error?.errorMessage);
  }
};

/**
 * @param {Error | AxiosError} error
 */
export const handleAxiosError = async (error) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      /*API Error Detected */
      if (error?.response?.status) {
        const message = await axiosErrorHandling(error?.response?.status);
        errorAlertPop(message);
      } else {
        Swal.fire(
          "Server Error",
          "The server is currently unavailable. Please try again later.",
          "error"
        );
      }
    } else {
      /*Network Error */
      errorAlertPop(error?.message);
    }
  } else {
    /*Javascript Error */
    await otherErrors(error);
  }
};

/**
 *
 * @param {AxiosRequestConfig} config
 * @param {(boolean) => void} setLoading
 * @param {boolean} showScucessAlert
 * @param {string} alertMessage
 * @returns
 */
export const fetchAPI = (
  config,
  setLoading,
  showScucessAlert,
  alertMessage
) => {
  return new Promise((resolve) => {
    if (setLoading) setLoading(true);
    axiosInstance(config)
      .then((data) => {
        if (data?.data?.errorCode && data?.data?.errorCode !== 0)
          throw data?.data;
        else {
          if (showScucessAlert) {
            const message = alertMessage || data?.data?.errorMessage;
            sucessAlertPop(message);
          }
          if (setLoading) setLoading(false);
          return resolve({
            data: data?.data,
            error: null,
          });
        }
      })
      .catch(async (error) => {
        await handleAxiosError(error);
        if (setLoading) setLoading(false);
        return resolve({ data: null, error });
      });
  });
};
