import { SECRETKEY } from "../constants/keyData";
import { fetchAPI, createHeader } from "./APICallHandler";
import {
  USER_STORAGE,
  USER_STORE_TOKEN_KEY,
  deleteDatabase,
  deleteObjectStore,
  getItem,
} from "./SecuredDatabaseHelper";
import CryptoJS from "crypto-js";

export const domain = process.env.REACT_APP_API_URL;
const clientCode = process.env.REACT_APP_CLIENT_CODE;
const clientPwd = process.env.REACT_APP_CLIENT_PWD;

export const headersToCreate = {
  clientCode: clientCode,
  clientPwd: clientPwd,
  "Content-Type": "application/json",
};

/**
 *
 * @param {object} payload
 * @returns
 */
async function signup(payload) {
  const headers = createHeader(headersToCreate);
  return await fetchAPI({
    url: "preLogin/userRegistration",
    headers,
    method: "POST",
    data: {
      ...payload,
    },
  });
}

/**
 * @returns
 */
async function getAllLoggedInSessions() {
  const authtoken = await getItem(USER_STORE_TOKEN_KEY, USER_STORAGE);
  const maintoken = decryptData(authtoken);
  const headersToCreateAfterLogin = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${maintoken}`,
  };
  const headers = createHeader(headersToCreateAfterLogin);
  return await fetchAPI({
    url: "/postLogin/getAllLoggedInSessions",
    method: "GET",
    headers,
  });
}

async function expireOtherSession(payload) {
  const authtoken = await getItem(USER_STORE_TOKEN_KEY, USER_STORAGE);
  const maintoken = decryptData(authtoken);
  const headersToCreateAfterLogin = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${maintoken}`,
  };
  const headers = createHeader(headersToCreateAfterLogin);
  return await fetchAPI(
    {
      url: "/postLogin/expireOtherSession",
      method: "POST",
      data: {
        ...payload,
      },
      headers,
    },
    null,
    true
  );
}

/**
 *
 * @returns
 */
async function getConfigurations() {
  const headers = createHeader(headersToCreate);
  return await fetchAPI({
    url: "/preLogin/getConfigurations",
    method: "GET",
    headers,
  });
}

/**
 * @param {object} payload
 * @returns
 */
async function userLogin(payload) {
  const headers = createHeader(headersToCreate);
  return await fetchAPI({
    url: "/preLogin/login",
    method: "POST",
    data: {
      ...payload,
    },
    headers: {
      ...headers,
    },
  });
}

async function logout() {
  const authtoken = await getItem(USER_STORE_TOKEN_KEY, USER_STORAGE);
  const maintoken = decryptData(authtoken);
  const headersToCreateAfterLogin = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${maintoken}`,
  };
  const headers = createHeader(headersToCreateAfterLogin);
  const { data, error } = await fetchAPI({
    url: "/postLogin/logOut",
    method: "GET",
    headers,
  });
  if (data) {
    await deleteDatabase(USER_STORAGE);
    return { data, error };
  } else return { data, error };
}

function leftShiftEncrypt(text) {
  const shift = 2;
  let encryptedText = "";
  if (text) {
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);
      const encryptedCharCode = charCode << shift;
      encryptedText += String.fromCharCode(encryptedCharCode);
    }

    return encryptedText;
  }
}

function leftShiftDecrypt(encryptedText) {
  const shift = 2;
  let decryptedText = "";
  if (encryptedText) {
    for (let i = 0; i < encryptedText.length; i++) {
      const charCode = encryptedText.charCodeAt(i);
      const decryptedCharCode = charCode >> shift;
      decryptedText += String.fromCharCode(decryptedCharCode);
    }

    return decryptedText;
  }
}

export function decryptData(encryptedData) {
  if (encryptedData) {
     const leftDecrypt = leftShiftDecrypt(encryptedData);
     if(leftDecrypt){
      var bytes = CryptoJS.AES.decrypt(leftDecrypt, SECRETKEY);
      if (bytes) {
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
      }
     }
  }
}

export function encryptData(data) {
  if (data) {
    var ciphertext = CryptoJS.AES?.encrypt(data, SECRETKEY);
    let lefttEncrypt;
    if(ciphertext){
      lefttEncrypt = leftShiftEncrypt(ciphertext?.toString())
    }
    return lefttEncrypt;
  }
}

export default {
  signup,
  getConfigurations,
  getAllLoggedInSessions,
  userLogin,
  logout,
  expireOtherSession,
};
