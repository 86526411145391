import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoutIcon from "../../assets/logout_icon.png";
import {
  USER_STORAGE,
  USER_STORE_USER_KEY,
  deleteDatabase,
  getItem,
} from "../../services/SecuredDatabaseHelper";
import { useNavigate } from "react-router-dom";
import API_SERVICE, { decryptData } from "../../services/api.service";
import LoadingComp from "../Popup/LoadingComp";
import { sucessAlertPop } from "../../services/APICallHandler";

const Root = styled.div`
  position: sticky;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid #c8cbd9;
  background-color:#fff;
`;

const Nav = styled.div`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  marquee{
    color:#24aae2;
    font-weight:600;
  }
`;

const NotificationBtn = styled.button`
  background-color: #24AAE2;
  padding: 5px 15px;
  border-radius: 23px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  column-gap: 5px;
  margin-right: 10px;
  border:none;
`;

export const Div = styled.div`
  position: relative;
  width: ${(p) => (p.width ? `${p.width}` : "100%")};
  display: flex;
  align-items: ${(p) => (p.alignitems ? `${p.alignitems}` : "center")};
  justify-content: ${(p) => (p.justifyContent ? `${p.justifyContent}` : "")};
  flex-direction: ${(p) => (p.flexdirection ? `${p.flexdirection}` : "row")};
  color: #828194;
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

export const Image = styled.img`
  width: ${(p) => (p.width ? `${p.width}` : ``)};
  min-width: ${(p) => (p.width ? `${p.width}` : ``)};
  height: ${(p) => (p.height ? `${p.height}` : ``)};
`;

export const Text = styled.div`
  font-size: ${(p) => (p.size ? `${p.size}` : "14px")};
  font-weight: ${(p) => (p.weight ? `${p.weight}` : "400")};
  line-height: ${(p) => (p.lineHeight ? `${p.lineHeight}` : "19px")};
  color: ${(p) => (p.color ? p.color : "#828194")};

  > img {
    width: ${(p) => (p.width ? p.width : "24px")};
    max-height: ${(p) => (p.width ? p.width : "24px")};
    min-width: ${(p) => (p.width ? p.width : "24px")};
  }
`;

export default function Header() {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const user = await getItem(USER_STORE_USER_KEY, USER_STORAGE);
      if (user?.authToken) {
        setUsername(`Hi, ${decryptData(user?.firstName)} ${decryptData(user?.lastName)}`);
        setUser(user);
      } else {
        navigate("/login");
      }
    })();
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await API_SERVICE.logout();
    if (data) {
      sucessAlertPop("User Logout Sucessfully.");
      setLoading(false);
      navigate("/login");
    } else setLoading(false);
  };

  return (
    <Root>
      {loading && <LoadingComp />}
      <Nav>
        <Div flexdirection="column" alignitems="flex-start" width="100%">
          <marquee>{username}</marquee>
        </Div>
        <span>
          <UserWrapper>
            <NotificationBtn
              title="Logout"
              style={{
                marginBottom: "-6px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
            >
              <Image
                src={logoutIcon}
                alt="user icon"
                width="20px"
                onClick={handleLogout}
              />
            </NotificationBtn>
          </UserWrapper>
        </span>
      </Nav>
    </Root>
  );
}
