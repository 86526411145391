import React from "react";
import API_SERVICE from "../../services/api.service";

export const AuthContext = React.createContext({
  configuration: {},
});

const AuthContextProvider = (props) => {
  const [configuration, setConfiguration] = React.useState({});

  React.useEffect(() => {
    (async function () {
      const { data } = await API_SERVICE.getConfigurations();
      if (data?.data) {
        setConfiguration(data?.data);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{ configuration }}
    >
        {props.children}
    </AuthContext.Provider>
  );

};

export default AuthContextProvider;
