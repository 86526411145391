import React from "react";
import logoImage from "../../assets/logo.png";
import { AuthLeftSideWrapper, BannnerImage, LogoWrapper } from "../stylecomponents/stylecomponents";

export default function BannerSection() {
  return (
    <AuthLeftSideWrapper>
      {/* <BannnerImage src={bannerImage} alt="background image" /> */}
      <LogoWrapper src={logoImage} alt="logo_image" />
    </AuthLeftSideWrapper>
  );
}
