import React from "react";
import styled from "styled-components";
import brandlogo from '../../assets/light_bg_logo.png';

const Root = styled.div`
position:sticky;
top:0px;
width: 100%;
background: #F1F2F7;
height: 100vh;
padding: 15px;
`;
const Logowrapper = styled.div`

`;
const Image = styled.img`
width:120px;
`;

const LinkWrapper = styled.div`
margin-top:30px;
background: #d9f1fc;
padding:15px;
color:#24aae2;
border-radius:6px;
font-weight:500;
`;
export default function LeftSideBar() {
  return (
    <Root>
      <Logowrapper>
        <Image src={brandlogo} alt="brand logo"/>
        
      </Logowrapper>
         <LinkWrapper>Home</LinkWrapper>
    </Root>
  );
}
