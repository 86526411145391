import React, { useContext, useEffect, useState } from "react";
import API_SERVICE, { decryptData, encryptData } from "../services/api.service";
import {
  AuthContainer,
  AuthRightSideWrapper,
  Button,
  DataWrapper,
  Heading,
  Input,
  InputWrapper,
  LinkWrapper,
  Para,
  ShowPasswordICon,
} from "../components/stylecomponents/stylecomponents";
import { Link } from "react-router-dom";
import BannerSection from "../components/authComponents/BannerSection";
import { getDeviceInfo } from "../constants/common";
import {
  addUpdateItem,
  deleteDatabase,
  deleteObjectStore,
  USER_STORAGE,
  USER_STORE_TOKEN_KEY,
  USER_STORE_USER_KEY,
} from "../services/SecuredDatabaseHelper";
import { retryVerification } from "../constants/fetchRetryVerify";
import { useNavigate } from "react-router-dom";
import LoadingComp from "../components/Popup/LoadingComp";
import { AuthContext } from "../components/authComponents/AuthProvider";
import openEye from "../assets/openeye.png";
import closeEye from "../assets/closeeye.png";

export default function SignIn() {
  /*State of the component */
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(100);
  const [showPassword, setShowPassword] = useState(false);
  const [inputError, setInputError] = useState({
    userName: false,
    password: false,
  });

  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  useEffect(() => {
    const fetchDetails = async () => {
      const { deviceInfo: deviceData, error } = await getDeviceInfo();
      setDeviceInfo(deviceData);
    };
    fetchDetails();
  }, []);

  const saveToIndexDb = async (response) => {
    const storeresponse = {
      authToken: response?.authToken,
      firstName: response?.firstName,
      lastName: response?.lastName,
    };
    await addUpdateItem(
      response?.authToken,
      USER_STORE_TOKEN_KEY,
      USER_STORAGE
    );
    await addUpdateItem(storeresponse, USER_STORE_USER_KEY, USER_STORAGE);
    navigate("/");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userName || !password) {
      setInputError({
        userName: userName ? false : true,
        password: password ? false : true,
      });
      return;
    }
    await deleteObjectStore(USER_STORAGE);
    const { data: api_response } = await API_SERVICE.userLogin({
      userName: userName,
      password: password,
      deviceName: deviceInfo?.deviceName,
      deviceType: "PC_BROWSER",
      fcmIdAndroid: null,
      deviceNumber: deviceInfo?.deviceId,
    });
    if (api_response?.errorCode === 0) {
      if (api_response?.data?.verificationReq) {
        setLoading(true);
        const { response } = await retryVerification(
          api_response?.data?.requestId,
          api_response?.data?.userId,
          20,
          setLoading,
          setProgress
        );
        if (response) {
          if (response && response?.data) {
            let temp = { ...response?.data };
            const decToken = encryptData(temp?.authToken);
            const decFistname = encryptData(temp?.firstName);
            const decLastname = encryptData(temp?.lastName);
            temp["authToken"] = decToken;
            temp["firstName"] = decFistname;
            temp["lastName"] = decLastname;
            await saveToIndexDb(temp);
          }
        }
      } else {
        if (api_response && api_response?.data) {
          let temp = { ...api_response?.data };
          const decToken = encryptData(temp?.authToken);
          const decFistname = encryptData(temp?.firstName);
          const decLastname = encryptData(temp?.lastName);
          temp["authToken"] = decToken;
          temp["firstName"] = decFistname;
          temp["lastName"] = decLastname;
          await saveToIndexDb(temp);
        }
 
      }
    }
  };

  return (
    <AuthContainer>
      <BannerSection />
      <AuthRightSideWrapper>
        {loading && <LoadingComp progress={progress} showprogress={true} />}
        <DataWrapper>
          <Heading>Login</Heading>
          <Para>Enter the details to log In</Para>
        </DataWrapper>

        <InputWrapper>
          <Input
            name="username"
            placeholder="Username"
            value={userName || ""}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
          {inputError.userName && (
            <span style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>
              {"username can't be empty"}
            </span>
          )}
        </InputWrapper>
        <InputWrapper>
          <Input
            placeholder="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={password || ""}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
          <ShowPasswordICon
            src={showPassword ? openEye : closeEye}
            onClick={() => setShowPassword(!showPassword)}
          />
          {inputError.password && (
            <span style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>
              {"password can't be empty"}
            </span>
          )}
        </InputWrapper>
        <LinkWrapper>
          <Link to={"#"}>Forgot Password? </Link>
        </LinkWrapper>
        <Button onClick={submitHandler}>Login</Button>
        <LinkWrapper style={{ justifyContent: "center" }}>
          Don’t have a account ? <Link to={"/signup"}> Sign Up</Link>
        </LinkWrapper>
      </AuthRightSideWrapper>
    </AuthContainer>
  );
}
