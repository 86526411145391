import React, { useEffect } from "react";
import styled from "styled-components";
import ProgressBar from "./ProgressBar";

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div`
width:500px;
background-color: #fff;
border-radius:10px;
padding:50px 10px;
display: flex;
flex-direction:column;
align-items: center;
justify-content:center ;
`;
const LodingIcon = styled.div`
.loading-circle {
  border: 4px solid #c5d6e1;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; /* Adjust the duration and animation timing function as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;
const LoadingText = styled.div`
font-size:20px;
font-weight:600;
color:#24AAE2;
margin:20px 0px;
`;

const ProgressText = styled.div`

`;
export default function LoadingComp({progress, showprogress}) {
  return (
    <Overlay>
      <Root>
        <LodingIcon>
        <div className="loading-circle"></div>
        </LodingIcon>
        {showprogress && <>
          <LoadingText>Waiting For Approval</LoadingText>
          <ProgressText>
          </ProgressText>
          <ProgressBar bgcolor="#24AAE2" progress={progress} height={20}/>
        </>}
      </Root>
    </Overlay>
  );
}
