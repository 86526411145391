import CryptoJS from "crypto-js";
import { deleteDatabase } from "../services/SecuredDatabaseHelper";
import Bowser from "bowser";

const getSystemFont = () => {
  const body = document.querySelector("body");
  const tempElement = document.createElement("div");
  tempElement.textContent = "ABC";
  tempElement.style.cssText =
    "position: absolute; left: -9999px; top: -9999px; font-size: 12px;";

  body.appendChild(tempElement);
  const systemFont = window.getComputedStyle(tempElement).fontFamily;
  body.removeChild(tempElement);
  return systemFont;
};

const sha256 = async (input) => {
  try {
    const hash = CryptoJS.MD5(input).toString();
    return hash;
  } catch (err) {
    return input;
  }
};

export const getDeviceInfo = async () => {
  if ("navigator" in window) {
    const { platform, userAgent } = window.navigator;
    const { clientInformation, screen } = window;
    const { userAgentData } = clientInformation || {};
    const deviceTypeRegex = /mobile|tablet|ipad|iphone|ipod|android/i;
    const systemNameRegex = /windows|macintosh|mac os x|linux/i;
    const isMobile = userAgentData?.mobile;
    const devicePlatform = userAgentData?.platform;
    const deviceMemory = clientInformation?.deviceMemory;
    const hardwareConcurrency = clientInformation?.hardwareConcurrency;
    const devicePixleRatio = window?.devicePixelRatio;
    // Extract device type
    const deviceTypeMatch = userAgent.match(deviceTypeRegex);
    const deviceType = deviceTypeMatch ? deviceTypeMatch[0] : "Desktop";

    // Extract system name
    const systemNameMatch = userAgent.match(systemNameRegex);
    const systemName = systemNameMatch ? systemNameMatch[0] : "Unknown";
    const systemFont = getSystemFont();
    const { width, height } = window.screen;
    const resolution = `${width}*${height}`;
    const browserInfo = Bowser.parse(userAgent);
    const browserName = browserInfo.browser.name;
    const deviceId = `${platform}-${resolution}-${devicePixleRatio}-${
      isMobile ? "mobile" : "desktop"
    }-${devicePlatform}-${deviceMemory}-${hardwareConcurrency}-${systemFont}-${userAgent}`;
    const deviceIdNum = await sha256(deviceId);
    return {
      deviceInfo: {
        userAgent,
        platform,
        deviceName: `${browserName} browser(${deviceType})`,
        resolution,
        deviceId: deviceIdNum,
        deviceType,
        systemName,
      },
    };
  } else {
    return {
      error: "Please provide access to get Device Info",
    };
  }
};

export const errorHandling = async (response) => {
  const errorCode = response?.errorCode;

  /*Bad request */
  if (errorCode === 400) {
    return "Invalid request";
  }

  /*Unauthorized request */
  if ([413, 401].includes(errorCode)) {
    return "Access denied";
  }

  /*Not found exception*/
  if (errorCode === 404) {
    return "The requested information could not be found";
  }

  /*conflict expection */
  if (errorCode === 409) {
    return "Conflict occurred";
  }

  /*Internal server error */
  if ([500, 503, 502, 504].includes(errorCode)) {
    return "Service under maintainance! Please try after some time.";
  }
};
