import React, { useEffect, useState } from "react";
import API_SERVICE from "../services/api.service";
import BaseLayout from "../components/Layput/Base";
import styled from "styled-components";
import { Heading, Para } from "../components/stylecomponents/stylecomponents";
import privacyImage from "../assets/privacy.png";
import DeviceDataCard from "../components/homeComponents/DeviceDataCard";

const Container = styled.div`
  padding: 40px 168px;
  display: flex;
  flex-direction: column;
`;

const TextContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  margin-bottom: 30px;
`;
const ParaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.img`
  width: 120px;
`;
export default function Home() {
  const [ownloginData, setOwnLoginData] = useState();
  const [loginData, setLoginData] = useState();

  const getLoginData = async () => {
    const { data: api_response } = await API_SERVICE.getAllLoggedInSessions();
    if (api_response?.errorCode === 0) {
      setOwnLoginData(api_response?.data?.loggedInDeviceDetails);
      setLoginData(api_response?.data?.otherDeviceDetails);
    }
  };
  useEffect(() => {
    getLoginData();
  }, []);

  return (
    <BaseLayout>
      <Container>
        <TextContainerWrapper>
          <ImageWrapper src={privacyImage} />
          <Heading>Manage Access and Devices</Heading>
          <ParaWrapper>
            <Para>
              These signed-in devices have recently been active on this account.
            </Para>
            <Para>You can sign out any unfamiliar devices.</Para>
          </ParaWrapper>
        </TextContainerWrapper>

        <DeviceDataCard data={ownloginData} currentData={true}></DeviceDataCard>

        {loginData?.length > 0 &&
          loginData?.map((item, index) => {
            return <DeviceDataCard data={item} key={index} getLoginData={getLoginData}></DeviceDataCard>;
          })}
      </Container>
    </BaseLayout>
  );
}
