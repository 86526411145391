import { headersToCreate, domain } from "../services/api.service";
import httpRequestService from "../services/http-request.service";
import {
  errorAlertPop,
  otherErrors,
} from "../services/APICallHandler";
/**
 * @param {string} requestId
 * @param {string | number} userId
 * @param {number} n (Number of iteration)
 * @param {*} setLoading
 * @param {*} setProgress
 * @returns
 */
export const retryVerification = (
  requestId,
  userId,
  n,
  setLoading,
  setProgress
) => {
  setProgress((prevCount) =>
    prevCount <= 100 ? prevCount - 5 : prevCount - 10
  );
  const headers = httpRequestService.createHeader(headersToCreate);
  const url = `${domain}preLogin/verifyLoginSession?requestId=${requestId}&userId=${userId}`;
  return new Promise((resolve) => {
    fetch(url, { method: `GET`, headers: headers })
      .then((res) => res.json())
      .then((response) => {
        if (response?.errorCode === 6922) {
          throw response;
        } else if (response?.errorCode === 0) {
          setLoading(false);
          setProgress(100);
          return resolve({
            response,
          });
        } else {
          throw response;
        }
      })
      .catch(async (error) => {
        if (error?.errorCode === 6922) {
          errorAlertPop(error?.errorMessage);
          setLoading(false);
          setProgress(100);
          return resolve({
            error,
          });
        } else if (n === 1 || n < 1) {
          if (error?.errorCode) {
            await otherErrors(error);
          } else {
            errorAlertPop("Something went wrong, please try again later.");
          }
          setProgress(100);
          setLoading(false);
          // return resolve({
          //   error,
          // });
          return errorAlertPop("Request Time Out");
        } else {
          setTimeout(() => {
            resolve(
              retryVerification(
                requestId,
                userId,
                n - 1,
                setLoading,
                setProgress
              )
            );
          }, 3000);
        }
      });
  });
};
