import { openDB, deleteDB } from "idb";

const databaseName = process.env.REACT_APP_SECURED_DATABASE_NAME;

export const USER_STORE_TOKEN_KEY = "authToken";
export const USER_STORE_USER_KEY = "userData";

export const USER_STORAGE = "users";

const openDataBase = async (storageName) => {
  return openDB(databaseName, 1, {
    upgrade(db) {
      db.createObjectStore(storageName);
    },
  });
};

/**
 * @async
 * @description delete database
 */
export const deleteDatabase = async () => {
  const db = await openDB(databaseName, 1);

  /**start a transaction and clear storage */
  const transaction = db.transaction(USER_STORAGE, "readwrite");
  if (transaction) {
    const objectStore = transaction.objectStore(USER_STORAGE);
    if (objectStore) objectStore.clear();

    /**Commit the trasnsaction */
    if (transaction) await transaction.done;
  }
  /**close db */
  if (db) {
    db.close();
  }
  /**Delete db */
  // await deleteDB(databaseName);
};

/**
 * @async
 * @param {*} data
 * @param {*} keyName
 * @description add or update storage value for a key in database
 */
export const addUpdateItem = async (data, keyName, storageName) => {
  const db = await openDataBase(storageName);
  const tx = db.transaction(storageName, "readwrite");
  const store = tx.objectStore(storageName);
  await store.put(data, keyName);
  await tx.done;
  await db.close();
};

/**
 * @async
 * @param {*} keyName
 * @returns {Promise<void>}
 * @description retrieve data from database
 */
export const getItem = async (keyName, storageName) => {
  const db = await openDataBase(storageName);
  const tx = db.transaction(storageName, "readonly");
  const store = tx.objectStore(storageName);
  const key = await store.get(keyName);
  await db.close();
  return key;
};

/**
 * @async
 * @param {*} keyName
 * @description delete an item from database for a specific key
 */
export const deleteItem = async (keyName, storageName) => {
  const db = await openDataBase(storageName);
  const tx = db.transaction(storageName, "readwrite");
  const store = tx.objectStore(storageName);
  await store.delete(keyName);
  await tx.done;
  await db.close();
};

/**
 * @async
 * @param {*} storageName
 * @description To delete a particular object storage
 */
export const deleteObjectStore = async (storageName) => {
  const db = await openDB(databaseName, 1);
  if (db.objectStoreNames.contains(storageName)) {
    db.onupgradeneeded = (event) => {
      const upgradeDb = event.target.result;
      upgradeDb.deleteObjectStore(storageName);
    };
    db.onsuccess = () => {
      db.close();
    };
  } else {
    db.close();
  }
};
