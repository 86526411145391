import styled from "styled-components";
import bannerImage from "../../assets/login_bg.png";

export const Nav = styled.nav``;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Div = styled.div``;

export const Input = styled.input`
  width: 100%;
  background-color: #f0f0f0;
  border: none;
  outline: none;
  height: 49px;
  padding: 10px;
  border-radius: 8px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

export const Button = styled.button`
  background: #24aae2;
  color: #fff;
  border: none;
  height: 49px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  border-radius: 30px;
`;

export const Heading = styled.div`
  font-size: ${(p) => (p.size ? p.size : "34px")};
  font-weight: 600;
  color: ${(p) => (p.color ? p.color : "#262626")};
`;

export const Label = styled.div`
  margin-bottom: 10px;
  color: ${(p) => (p.color ? p.color : " #565656")};
`;

export const Image = styled.img``;

export const AuthLeftSideWrapper = styled.div`
  width: 50%;
  position: relative;
  min-height:100vh;
  background-image: url(${bannerImage});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

`;

export const AuthRightSideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  padding: 40px 10%;
`;

export const BannnerImage = styled.img`
  width: 100%;
  min-height:100vh;
`;

export const AuthContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const LogoWrapper = styled.img`
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 10px;
`;

export const Para = styled.div`
  color: ${(p) => (p.color ? p.color : " #565656")};
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  row-gap: 10px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  a {
    color: #24aae2 !important;
    text-decoration: none;
    font-weight: 500;
    margin-left: 5px;
  }
`;

export const ShowPasswordICon = styled.img`
width:25px;
position: absolute;
top:14px;
right:10px;
z-index: 10;
cursor: pointer;
`;
