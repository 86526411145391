import errorcodes from "../constants/errorcodes";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/**
 * Create Headers for HTTP-Requests
 * @param records
 * @returns
 */
function createHeader(records) {
  const headers = {};
  const keys = Object.keys(records);

  if (keys?.length) {
    for (let index = 0; index < keys?.length; index++) {
      const key = keys[index];
      headers[key] = records[key];
    }
  }
  return headers;
}

/**
 * Create a GET Request to endpoint
 * @param url
 * @param headers
 * @param json
 * @returns
 */
function GET(url, headers, json = true) {
  return new Promise((resolve, reject) => {
    fetch(url, { method: `GET`, headers: headers })
      .then((response) => {
        if (response.ok) {
          if (json) {
            return response.json();
          } else {
            return response;
          }
        } else {
          throw new Error(`HTTP error ${response.status}`);
        }
      })
      .then((resp) => {
        handleResponse(resp);
        resolve(resp);
      })
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
}

/**
 * Create a POST Reqeust to endpoint
 * @param url
 * @param headers
 * @param body
 * @param json
 * @returns
 */
function POST(url, headers, body, json = true) {
  return new Promise((resolve, reject) => {
    fetch(url, { method: "POST", headers: headers, body: JSON.stringify(body) })
      .then((response) => {
        if (response.ok) {
          if (json) {
            return response.json();
          } else {
            return response;
          }
        } else {
          throw new Error(`HTTP error ${response.status}`);
        }
      })
      .then((response) => {
        if (response?.body) {
          response.text().then((html) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            let responseData = doc.querySelector("input").getAttribute("value");
            if (responseData) {
              responseData = JSON.parse(responseData);
              handleResponse(responseData);
              resolve(responseData);
            }
          });
        } else {
          handleResponse(response);
          resolve(response);
        }
      })
      .catch((error) => {
        handleError(error);
        // reject(error);
      });
  });
}

/**
 * Create a PUT Request to endpoint
 * @param url
 * @param headers
 * @param body
 * @param json
 * @returns
 */
function PUT(url, headers, body, json = true) {
  return new Promise((resolve, reject) => {
    fetch(url, { method: `PUT`, headers: headers, body: JSON.stringify(body) })
      .then((resp) => {
        return json ? resp.json() : resp;
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        handleError(err);
        // reject(err);
      });
  });
}

/**
 * Create a DELETE Request to endpoint
 * @param url
 * @param headers
 * @param body
 * @param json
 * @returns
 */
function DELETE(url, headers, body, json = true) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: `DELETE`,
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((resp) => {
        return json ? resp.json() : resp;
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        handleError(err);
        // reject(err);
      });
  });
}

/**
 * Error Handler for API response
 * @param e
 */
function handleError(error) {
  console.error(error); // Log the error for debugging purposes
  Swal.fire(
    "Server Error",
    "The server is currently unavailable. Please try again later.",
    "error"
  );
  // Perform any additional error handling or actions here
  // ...
}

function handleResponse(response) {
  if (response?.errorCode === errorcodes.SUCCESS) {
    return response;
  }
 else if (
    response?.errorCode === errorcodes.SESSION_EXPIRED) {
    localStorage.clear();
    window.location.href = "/login";
  }
   else {
    Swal.fire({
      title: response?.errorMessage || response?.responseData?.message,
      text: "",
      icon: "error",
      showConfirmButton: true,
      timer: 5000, // Timeout duration in milliseconds
      timerProgressBar: true,
    });
  }
}

export default {
  createHeader,
  GET,
  POST,
  PUT,
  DELETE,
};
