import axios from "axios";

export const domain = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
