import React from "react";
import Header from "./Header";
import styled from "styled-components";
import { FlexContainer } from "../stylecomponents/stylecomponents";
import LeftSideBar from "./LeftSideBar";

const Root = styled.div`
  display: flex;
`;

const LeftSideBarwrapper = styled.div`
  background: #fff;
  height: 100vh;
  position: sticky;
  top:0px;
  width: 16rem;
  min-width: 16rem;
`;

const RightSideBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: calc(100% - 16rem);
`;

const PageWrapper = styled.div`
  /* margin-top: 80px; */
`;

export default function BaseLayout({ children }) {
  return (
    <Root>
      <LeftSideBarwrapper>
        <LeftSideBar />
      </LeftSideBarwrapper>
      <RightSideBar>
        <Header />
        {children}
      </RightSideBar>
    </Root>
  );
}
