import { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import {
  USER_STORAGE,
  USER_STORE_TOKEN_KEY,
  getItem,
} from "../../services/SecuredDatabaseHelper";

const HandeledRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(() => {
    (async () => {
      const tokenNumber = await getItem(USER_STORE_TOKEN_KEY, USER_STORAGE);
      setToken(tokenNumber);
      setLoading(false);
    })();
  }, []);

  if (!loading) {
    return token ? <Navigate to="/" replace /> : element;
  }
};

export default HandeledRoute;
