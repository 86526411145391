import React from "react";
import styled from "styled-components";
import deviceImage from "../../assets/DeviceMobileCamera.svg";
import logoutIcon from "../../assets/logout_icon.png";
import API_SERVICE from "../../services/api.service";

const DeviceCard = styled.div`
  box-shadow: 0px 2px 48px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${(p) => (p.currentData ? "#F1F2F7" : "#fff")};
  border-radius: 10px;
`;

const IconWrapper = styled.div`
  padding-right: 20px;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;
const Icon = styled.img`
  width: ${(p) => (p.width ? p.width : "30px")};
`;
const CurrentdeviceText = styled.div`
  color: ${(p) => (p.color ? p.color : "#262626")};
  font-family: 18px;
  font-weight: 600;
`;
const LoginTimeDataWrapper = styled.div`
  display: flex;
`;

const ActionIconWrapperbtn = styled.button`
  position: absolute;
  z-index: 2;
  right: 54px;
  top: 20px;
  cursor: pointer;
  background-color: #24aae2;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;
export default function DeviceDataCard({ data, currentData, getLoginData }) {
  const logoutdeviceHandler = async (deviceNumber) => {
    const { data } = await API_SERVICE.expireOtherSession({
      deviceNumber: deviceNumber,
    });
    if (data?.errorCode === 0) {
      getLoginData();
    }
  };
  return (
    <DeviceCard currentData={currentData}>
      <IconWrapper>
        <Icon src={deviceImage} />
      </IconWrapper>
      <DataWrapper>
        {currentData && (
          <CurrentdeviceText color="#24aae2">Current Device</CurrentdeviceText>
        )}
        <CurrentdeviceText>
          {data?.deviceName} {data?.city && <>,{data?.city}</>}
          {data?.country && <>,{data?.country}</>}
        </CurrentdeviceText>
        <LoginTimeDataWrapper>
          Last Login -  <div style={{marginLeft:"5px"}}> {data?.loginTime}</div>
        </LoginTimeDataWrapper>

        {!currentData && (
          <ActionIconWrapperbtn
            onClick={() => logoutdeviceHandler(data?.deviceNumber)}
            title="Logout from this device"
          >
            <Icon src={logoutIcon} alt="action icon" width={"20px"} />
          </ActionIconWrapperbtn>
        )}
      </DataWrapper>
    </DeviceCard>
  );
}
