import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import PageNotFound from "./Pages/PageNotFound";
import PrivateRoute from "./components/authComponents/PrivateRoute";
import HandeledRoute from "./components/authComponents/HandeledRoute";

function App() {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Route>
        <Route path="/login" element={<HandeledRoute element={<SignIn />} />} />
        <Route
          path="/signup"
          element={<HandeledRoute element={<SignUp />} />}
        />
        <Route path="*" element={<SignIn />} />
      </Routes>
    </div>
  );
}

export default App;
